class Menu {
    /**
     * Init menu app
     * */
    init() {
        const menuItems = document.querySelectorAll('.nav__header-first')
        const mobileMenuItems = document.querySelectorAll('.nav__mobile-first')

        this.mobileMenuSecondLevel(mobileMenuItems)
        this.desktopMenuSecondLevel(menuItems)
    }

    /**
     * Mobile menu second level toggle
     *
     * @param {NodeList} items - Mobile menu items
     * */
    mobileMenuSecondLevel(items) {
        items.forEach(item => {
            if(item.classList.contains('have-second')){
                let itemLink = item.querySelector('.nav__mobile-item')
                itemLink.addEventListener('click', (e) => {
                    e.preventDefault()
                    let itemChildren = item.querySelector('.nav__mobile-second')
                    if(itemChildren){
                        if(item.classList.contains('opened')){
                            item.classList.remove('opened')
                        }else{
                            item.classList.add('opened')
                        }
                    }
                })
            }
        })
    }

    /**
     * Desktop menu second level toggle
     *
     * @param {NodeList} items - Desktop menu items
     * */
    desktopMenuSecondLevel(items) {
        items.forEach(item => {
            let itemLink = item.querySelector('.nav__header-item'),
                itemChildren = item.querySelector('.nav__header-second'),
                _this = this
            if(itemChildren){
                itemLink.addEventListener('mouseover', (e) => {
                    item.classList.add('opened')
                })
                itemLink.addEventListener('mouseleave', (e) => {
                    setTimeout(function (){
                        let childrenIsHovered = _this.checkHoveredChildren(item)
                        if(!childrenIsHovered){
                            item.classList.remove('opened')
                        }
                    }, 500)
                })
                itemChildren.addEventListener('mouseleave', (e) => {
                    setTimeout(function (){
                        let childrenIsHovered = _this.checkHoveredChildren(item)
                        if(!childrenIsHovered){
                            item.classList.remove('opened')
                        }
                    }, 500)
                })
            }
        })
    }

    /**
     * Checking if menu children is hovered
     *
     * @param {Node} item - Menu item
     *
     * @return boolean
     * */
    checkHoveredChildren(item) {
        let linkHovered = item.querySelector('.nav__header-item:hover'),
            menuHovered = item.querySelector('.nav__header-second:hover'),
            result = true
        if(!linkHovered && !menuHovered){
            result = false
        }
        return result
    }
}

export default Menu