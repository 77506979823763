import IMask from 'imask'

class MaskPhone {
    init() {
        let phoneInputs = document.querySelectorAll('input[type=tel]'),
            maskOptions = {
                mask: '+{7} (000) 000-00-00'
            }

        phoneInputs.forEach(input => {
            let mask = IMask(input, maskOptions)
        })
    }
}

export default MaskPhone