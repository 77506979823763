class Popup {
    vars = {
        html: '',
        popups: [],
        activePopup: ''
    }

    /**
     * Init popup app
     * */
    init() {
        this.vars.html = document.querySelector('html');
        this.vars.popups = document.querySelectorAll('[data-popup]');

        this.vars.popups.length && this._events();
    }

    /**
     * Adding click event handlers on particular DOM elements to open and close popups
     * */
    _events() {
        let _this = this
        document.addEventListener('click', (e) => {
            if (e.target.matches('[data-popup-open]')) {
                let needPopup = e.target.getAttribute('data-popup-open'),
                    videoCode = e.target.getAttribute('data-source-code'),
                    imageSrc = e.target.getAttribute('data-image-src'),
                    popupTitle = e.target.getAttribute('data-title'),
                    searchPop = document.querySelector('[data-popup=' + needPopup + ']');

                _this._searchAndOpen(needPopup, searchPop, videoCode, imageSrc, popupTitle);
            }
        }, false);
        for (let i = 0; i < _this.vars.popups.length; i++){
            _this.vars.popups[i].addEventListener('click', (e) => {
                if(e.target.classList.contains('popup__close')){
                    _this._closePopup();
                }else if(e.target.classList.contains('popup__wrap')){
                    e.preventDefault();
                    _this._closePopup();
                }
            }, false);
        }

        window.addEventListener('keydown', function(e){
            if (e.code === 'Escape') {
                _this._closePopup();
            }
        });
    }

    /**
     * Opening popup
     *
     * @param {string} needPopup - searchable popup code
     * @param {Element} searchPop - searchable popup DOM element
     * @param {string} videoCode - video code
     * @param {string} imageSrc - image source
     * @param {string} popupTitle - popup title
     * */
    _searchAndOpen(needPopup, searchPop, videoCode = '', imageSrc = '', popupTitle = '') {
        if (needPopup.length && searchPop) {
            this.vars.activePopup = searchPop;

            this._changeHTMLOverflow(true);

            searchPop.classList.add('popup__visible');

            /* Video popup */
            if(videoCode){
                this.lazyVideo(videoCode, '[data-popup="video"] .popup__box-content');
            }

            /* Image popup */
            if(imageSrc){
                this.lazyImage(imageSrc, '[data-popup="image"] .popup__box-content');
            }

            /* Popup caption */
            if(popupTitle){
                searchPop.querySelector('.popup__caption').innerHTML = popupTitle;
            }
        }
    }

    /**
     * Adding special class on html tag to prevent scrolling while popup is opened
     * (Add CSS styles to this class if needed)
     * */
    _changeHTMLOverflow(val) {
        const defaultInherit = val || false;

        defaultInherit ? this.vars.html.classList.add('fix-over') : this.vars.html.classList.remove('fix-over');
    }

    /**
     * Closing active popup
     * */
    _closePopup() {
        let _this = this

        _this._changeHTMLOverflow();
        if(typeof _this.vars.activePopup !== 'undefined'){
            _this.vars.activePopup.classList.remove('popup__visible');

            /* stop playing YouTube video */
            if(_this.vars.activePopup.getAttribute('data-popup') === 'video'){
                setTimeout(function (){
                    _this.vars.activePopup.querySelector('[data-popup="video"] .popup__box-content').innerHTML = '';
                },300);
            }

            /* removing previous img tag */
            if(_this.vars.activePopup.getAttribute('data-popup') === 'image'){
                setTimeout(function (){
                    _this.vars.activePopup.querySelector('[data-popup="image"] .popup__box-content').innerHTML = '';
                },300);
            }
        }
    }

    /**
     * Creating iframe with youtube video
     *
     * @param {string} videoCode - Youtube video code
     * @param {string} selector  - Parent selector for video iframe
     */
    lazyVideo(videoCode, selector) {
        if(!videoCode){
            return;
        }

        let iframe = document.createElement("iframe");

        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute("allow", "autoplay");
        iframe.setAttribute("rel", "nofollow");
        iframe.setAttribute("src", "https://www.youtube.com/embed/"+ videoCode +"?rel=0&autoplay=1");

        document.querySelector(selector).appendChild(iframe);
    }

    /**
     * Creating img tag
     *
     * @param {string} imageSrc - Image source
     * @param {string} selector - Parent selector for im tag
     */
    lazyImage(imageSrc, selector){
        if(!imageSrc){
            return;
        }

        let img = document.createElement("img");
        img.setAttribute("src", imageSrc);

        document.querySelector(selector).appendChild(img);
    }
}

export default Popup